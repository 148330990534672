import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ServiceNoneLink from "../../components/service-none-link"

import * as Styles from "../styles/index.module.scss"

import iconBookkeeping from "../../images/gyosei/bookkeeping.svg"
import iconPrenup from "../../images/gyosei/prenup.svg"
import iconWill from "../../images/gyosei/will.svg"
import iconPapers from "../../images/gyosei/papers.svg"
import iconFace from "../../images/common/harukawa.png"

const IndexPage = () => {

    const DATA = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            divisionJa { gyosei }
          }
        }
      }
    `
    )
    const GYOSEI_JA = DATA.site.siteMetadata.divisionJa.gyosei

    return (
        <Layout division='gyosei'>
            <Seo title={GYOSEI_JA} />
            <div id={Styles.gyosei} className={Styles.appeal}>
                <StaticImage
                    src="../../images/common/circle_gyosei.png"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt={GYOSEI_JA}
                    className={Styles.appeal_icon}
                />
            </div>
            <div className="flex flex-wrap justify-evenly my-8">
                <ServiceNoneLink
                    division='gyosei'
                    to="/gyosei/service/bookkeeping"
                    icon={iconBookkeeping}
                    title="会計記帳"
                    desc={[
                        '日々の記帳にご苦労されていませんか。より事業に専念するために、外部委託されてはいかがでしょう。日商簿記2級取得、個人事業経理担当の経験を活かして、お手伝いさせていただきます。',
                        'ご希望に応じて、領収書等の整理や回収、税理士事務所のご紹介、税理士事務所との連絡等も承ります。',
                    ]}
                    target={[
                        '帳簿をつけるのが大変/面倒',
                        '青色申告特別控除を受けたい',
                        'もっと事業に専念したい',
                        '経理をなんとなく家族に任せている',
                        '経理要員を雇う余裕はない',
                    ]}
                />

                <ServiceNoneLink
                    division='gyosei'
                    to="/gyosei/service/prenup"
                    icon={iconPrenup}
                    title="婚前契約書"
                    desc={[
                        '結婚前だからできる契約があります。不安なこと、約束しておきたいこと、生活のこと、将来のこと等、パートナーと話し合ってみませんか。',
                        '安心して結婚するために、結婚生活の安定のために、いざという時に困らないために、ぜひご相談ください。初めはおひとりでもかまいません。'
                    ]}
                    target={[
                        '結婚することに不安がある',
                        'パートナーと約束したいことがある',
                        '婚前財産がある',
                        '離婚経験がある',
                        '婚活中である'
                    ]}
                />

                <ServiceNoneLink
                    division='gyosei'
                    to="/gyosei/service/will"
                    icon={iconWill}
                    title="遺言書"
                    desc={[
                        '大切な財産を大切な方に遺すために、遺された方々を困らせてしまわないために、遺言書を書いてみませんか。法律上有効で、お客様のご希望にそった遺言書となるようお手伝いさせていただきます。',
                        'いざという時が来る前に、お早めにご準備いただくと安心です。まずはお話をお聞かせください。',
                    ]}
                    target={[
                        '配偶者や子どもがいない',
                        '事実婚をしている',
                        '複数回結婚していて相続が複雑',
                        'お世話になった方に財産を遺したい',
                        '特定の人や団体に財産を遺したい',
                    ]}
                />

                <ServiceNoneLink
                    division='gyosei'
                    to="/gyosei/service/papers"
                    icon={iconPapers}
                    title="各種書類"
                    desc={[
                        '行政書士は官公署に提出する書類、権利義務に関する書類、事実証明に関する書類を扱うことができます。(各種許認可申請、契約書、協議書、内容証明、定款、議事録、会計帳簿等)',
                        '書類作成についてのご相談がある方、一度お問い合わせください。ご相談内容やご希望に応じて、各士業のご紹介もさせていただきます。'
                    ]}
                    target={[
                        '必要な書類がわからない',
                        '書類の書き方がわからない',
                        '書類を書くのが面倒',
                        '有効な書類か確認したい',
                        '何を書けば良いか相談したい',
                    ]}
                />
            </div>
            <div className={Styles.greeting}>
                <div className={Styles.gyosei}>
                    <h1 className={Styles.index}>ようこそ行政書士事務所白玉へ</h1>
                    <div className={Styles.comment}>
                        <img src={iconFace} />
                        <p>
                            行政書士の春川里佳です。ご覧いただきありがとうございます。<br />
                            より良いお仕事を目指される方、結婚や相続が心配な方、書類の作成や手続きでお困りの方、できるだけ丁寧にお手伝いさせていただきます。<br />
                            女性の方が話しやすい方、大歓迎です。まだまだ女性や少数者には生きづらい世の中なので、少しでもお力になれたらと考えています。<br />
                            電話やオンライン通話でも対応いたします。まずはお気軽にお問い合わせください。
                        </p>
                    </div>
                    <div className={Styles.contact_button}>
                        <Link to="/gyosei/contact"><button>お問い合わせ</button></Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage
